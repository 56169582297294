<template>
  <div>
    <text-module>
      <div class="negative-row">
        <safety-tips-list :tips="tips"></safety-tips-list>
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import TextModule from "@/components/modules/TextModule.vue";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { defineComponent } from "vue";
import { SUBSCRIPTION_TIPS } from "@/data/safety-tips";

export default defineComponent({
  components: { TextModule, SafetyTipsList },
  setup() {
    return { tips: SUBSCRIPTION_TIPS };
  },
});
</script>
