
import TextModule from "@/components/modules/TextModule.vue";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { defineComponent } from "vue";
import { SUBSCRIPTION_TIPS } from "@/data/safety-tips";

export default defineComponent({
  components: { TextModule, SafetyTipsList },
  setup() {
    return { tips: SUBSCRIPTION_TIPS };
  },
});
